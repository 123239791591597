@import url("https://fonts.googleapis.com/css2?family=Edu+SA+Beginner:wght@700&family=Open+Sans:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Comfortaa:regular;bold;light");
@import url("https://fonts.googleapis.com/css?family=Inter:regular;bold;light");
@import url("https://fonts.cdnfonts.com/css/poetsen-one");

html,
body,
#__next {
    padding: 0;
    margin: 0;
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
}

input {
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
}

h1 {
    font-family: Comfortaa;
    font-weight: 400;
    font-size: 36px;
}

h2 {
    font-family: Comfortaa;
    font-weight: 400;
    font-size: 34px;
}

p {
    font-family: Inter;
    font-size: 16;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.selectedTab {
    background-color: #1c5c54;
    color: #fff;
    border-right: 3px solid #e55807;
    border-radius: 20px 0px 0px 20px;
    background: #1c5c54;
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.notSelectedTab {
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66px;
    border-radius: 20px 0px 0px 20px;
}

.notSelectedTab:hover {
    background-color: #1c5c54;
}

.ProseMirror {
    outline: none;
}

#lightbox-container-image img {
    width: auto;
    max-height: 520px;
}

.images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.images img {
    padding: 4px;
    border-radius: 16px;
}

.dotted-border {
    background-image: radial-gradient(circle, #f28d41 5px, transparent 0%);
    background-position: bottom;
    background-size: 100px 15px;
    background-repeat: repeat-x;
    height: 15px;
}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}

.iconlink:nth-child(2) {
    transition-delay: 200ms;
}
.iconlink:nth-child(3) {
    transition-delay: 400ms;
}
.iconlink:nth-child(4) {
    transition-delay: 600ms;
}

iframe {
    border: 8px solid #000;
    border-radius: 4px;
    min-width: 200px;
    min-height: 200px;
    display: block;
    outline: 0px solid transparent;
}

div[data-youtube-video] > iframe {
    cursor: move;
    aspect-ratio: 16 / 9;
    width: 100%;
}

.ProseMirror-selectednode iframe {
    transition: outline 0.15s;
    outline: 6px solid #fbbf24;
}

@media only screen and (max-width: 480px) {
    div[data-youtube-video] > iframe {
        max-height: 50px;
    }
}

@media only screen and (max-width: 720px) {
    div[data-youtube-video] > iframe {
        max-height: 100px;
    }
}

